.GoogleAddressInput-input-container {
  position: relative;

  .GoogleAddressInput-clear {
    position: absolute;
    cursor: pointer;
    inset-inline-end: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    border: 1px solid black;
    border-radius: 50%;
  }
}

.invalid-address {
  position: relative;

  input {
    border-bottom: 2px solid #f44336 !important;

    &::placeholder {
      color: #f44336;
    }
  }

  &:after {
    content: 'שדה חובה';
    position: absolute;
    bottom: -1rem;
    color: #f44336;
    font-size: 12px;
    line-height: 12px;
    right: 1rem;
    font-family: 'Rubik';
  }
}
