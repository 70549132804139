.App[dir="rtl"] {
  .previews > div {
    float: right !important;
  }
}

a:not(.MuiButton-root) {
  span {
    font-weight: bold !important;
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }
}

.list-container {
  label {
    font-weight: bold;
    min-width: 150px;
    text-align: start;

    & + div {
      display: flex;
    }
  }
}

.filter-field {
  button {
    margin: auto;

    svg {
      margin: 0;
    }
  }
}

//Login Page
@media screen and (max-width: 768px) {
  .MuiToolbar-root {
    .MuiButtonBase-root.MuiFab-root {
      right: unset;
      left: 20px;
    }
  }

  div#main-content {
    background-color: #F6F8FC;

    .list-page {
      & > .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
        flex-wrap: wrap;
        flex-direction: column-reverse;

        & > form {
          min-height: unset;
        }

        & > .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
          margin-right: auto;
          padding-left: 0 !important;
        }
      }
    }
  }

  .CustomLoginPage {
    &:before {
      content: '';
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      background: linear-gradient(
                      0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('/assets/login-background.jpg') no-repeat;
      background-size: cover !important;
      background-position: center;
      z-index: 1;
    }

    & > div[class*="MuiPaper-root"][class*="MuiCard-root"] {
      background-color: #ffffffdd !important;
      z-index: 2;
      width: 80vw !important;
      height: 60vh !important;
      max-height: 400px;
      padding-bottom: 0 !important;
      margin: auto;
      border-radius: 6px;
      box-shadow: 0 6px 9px #00000054;

      form {
        display: flex;
        margin-right: unset !important;
        margin-left: unset !important;
        width: 100% !important;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;

        &:before {
          margin: 1rem 0;
        }

        .MuiCardActions-root {
          padding: 0 !important;

          button {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }

    &:after {
      content: unset !important;
    }
  }
}

@media screen and (min-width: 768px) {
  .RaAutocompleteInput-textField {
    min-width: 310px !important;
  }

  .RaList-main > .RaList-actions {
    max-width: calc(100vw - 280px);
  }

  div#main-content {
    padding-left: 24px;

    // Super specific Bulk Action Cross fix
    .list-page .MuiToolbar-root.MuiToolbar-regular + div {
      .MuiToolbar-root.MuiToolbar-regular button.MuiButtonBase-root:first-child {
        margin-right: -.5rem;
        margin-left: .5rem;
      }
    }
  }

  .CustomLoginPage {
    & > div[class*="MuiPaper-root"][class*="MuiCard-root"] {
      margin-top: 0;
    }
  }
}

.CustomLoginPage {
  & > div[class*="MuiPaper-root"][class*="MuiCard-root"] {
    height: 100vh;
    right: 0;
    margin-left: auto;
    min-width: 55vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 15rem;

    & > div:first-child {
      display: none;
    }

    form {
      margin-right: auto;
      margin-left: auto;
      width: 60%;

      &:before {
        content: '';
        background-image: url('/assets/logo-with-name.png');
        max-height: 7rem;
        display: block;
        height: 7rem;
        width: 100%;
        z-index: 3;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      div[class*="MuiFormControl-root"] {
        * {
          font-family: -apple-system, Rubik !important;
        }

        div[class*="MuiInputBase-root"] {
          background-color: white !important;
          box-shadow: 0px 15px 20px #27313f0c !important;
          border-radius: 6px !important;

          &:before, &:after {
            border-bottom: none !important;
          }

        }

        label {
          z-index: 9;

          &.Mui-focused, &[class*="MuiFormLabel-filled-"] {
            right: 0 !important;
            transform: none !important;
            transition: none !important;
          }
        }
      }

      & [class*="MuiFormHelperText-root"] {
        text-align: start !important;
      }
    }

    .MuiButtonBase-root.MuiButton-root {
      margin: auto;
      display: block;
      padding: 1rem 2rem;
      font-size: 1.5em;
      line-height: 1.5rem;
      font-weight: bold;
    }
  }

  &:after {
    content: '';
    width: 45vw;
    height: 100vh;
    position: fixed;
    left: 0;
    background: linear-gradient(
                    0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('/assets/login-background.jpg') no-repeat;
    background-size: cover !important;
    background-position: center;
  }
}

//Layout
div#main-content {
  background-color: #F6F8FC;
}

//Custom
.ra-field[class*="ra-field-"] {
  display: flex !important;
  flex-direction: row;
  align-items: center;

  .RaLabeled-label {
    position: unset;
    font-weight: bold;
    padding: 0;
    text-align: start;
    margin-left: 2rem;
    transform: unset !important;
    min-width: 10rem;

    span {
      width: fit-content;
      white-space: nowrap;
    }
  }
}

// Create, Edit
.create-page, .edit-page {
  background-color: transparent;

  .MuiPaper-root.MuiCard-root {
    background-color: transparent !important;
    box-shadow: unset !important;

    .MuiCardContent-root {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.MuiPaginationItem-root svg {
  transform: rotate(180deg);
}

.previews img {
  max-height: 7rem;
  max-width: 80vw;
}
