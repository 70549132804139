#main-content > .RaFilter-form {
  max-width: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 10rem;

  & > * {
    width: 50%;
    flex: 50% 0;
  }
}