@media screen and (max-width: 768px) {
  .filter-field {
    flex-direction: row-reverse;
  }
}

.MuiTableCell-root.MuiTableCell-body {
  & > * {
    flex-wrap: nowrap;
    white-space: nowrap;
    max-width: 10vw;
    overflow-x: scroll;
  }

  & > span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.ra-input {
  max-width: 400px;

  div[data-testid="dropzone"] {
    border: 1px dashed #2f3ea0;
    border-radius: 6px;
  }
}

.MuiTableRow-root {
  img {
    max-height: 3rem;
    max-width: 3rem;
  }
}

.MuiInputBase-root.MuiFilledInput-root:not(.Mui-disabled) {
  &:hover, &:focus {
    background-color: white;
  }
}

.MuiPaper-root.MuiAppBar-colorSecondary {
  background-color: white !important;
  box-shadow: 0 6px 9px #00000054;
}

//Table
table.MuiTable-root {
  tbody tr {
    td {
      background-color: transparent;
    }
  }
}

body[dir="rtl"] {
  label[class*="MuiFormControlLabel-root"] {
    flex-direction: row-reverse;
    margin-left: auto;
    margin-right: 0;
  }

  *[class^="MuiTableCell"] {
    text-align: start !important;
  }

  .MuiInputBase-root .MuiSvgIcon-root {
    right: unset;
    left: 7px;
  }

  .MuiSelect-standard.MuiSelect-select {
    text-align: start;
    padding-left: 32px;
    padding-right: 12px;
  }

  .MuiButtonBase-root:not(.MuiFab-root):not(.MuiIconButton-root) {
    .MuiSvgIcon-root {
      margin-right: unset !important;
      margin-left: 8px !important;
    }
  }

  div[class*="list-page"] {
    & > *[class^="MuiToolbar-root"] {
      padding-left: 0 !important;
    }
  }

  header {
    *[class^="MuiToolbar-root"] {
      padding-left: 24px !important;
    }
  }

  *[class^="MuiToolbar-root"] {
    .MuiButton-startIcon {
      margin-right: 0;
      margin-left: 1rem;
    }

    .MuiButtonBase-root.MuiIconButton-root {
      position: relative;
    }

    h6 span {
      text-align: start !important;
      display: block;
    }
  }

  label[class*="MuiFormLabel"] {
    left: unset !important;
    width: fit-content;
    right: 1rem !important;

    &[class*="RaLabeled-label-"], &[class*="jss"] {
      right: 0 !important;
      transform: unset !important;
    }
  }

  .MuiAutocomplete-endAdornment {
    right: unset !important;
    left: 0 !important;
  }
}
