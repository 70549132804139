@import './styles/material-ui';
@import './styles/ra';

#main-content {
  padding-top: 1.5rem;
}

script ~ iframe {
  pointer-events: none;
}

body, * {
  direction: rtl !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pac-target-input {
  background-color: white !important;
  padding: 12px 10px;
  font: inherit;
  color: currentColor;
  border: 0;
  height: 1.1876em;
  display: block;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  border-radius: 6px;
  box-shadow: 0 15px 20px #27313f0c !important;
  width: 236px;
  font-family: -apple-system, Rubik !important;
  margin: 8px 0 20px;

  &, &:focus, &:focus-visible {
    outline: none !important;
  }
}

.show-page {
  .list-page {
    width: 100%;
  }
}
